<template>
  <div class="index-root-container">
    <!-- <img @click="goNewPage" :src="banner" class="index-banner" /> -->
    <a-carousel class="index-swapper" :after-change="onChange" dotPosition="top" dotsClass="index-circle-dot" autoplay>
      <div class="index-swapper-item-container div-background" v-for="(item,index) in index_banner_list"
        :key="'index-banner-'+index" :style="{
            backgroundImage: `url(${item.img_url})`,
          }" @click="goNewPage(item.link)">
        <!-- <img :src="icons.news_mask" class="index-mask" />
        <div class="index-swapper-content-container">
          <div class="index-swapper-item-title">{{ item.title }}</div>
          <div class="index-swapper-item-desc single-text">
            {{ item.short_desc }}
          </div>
        </div> -->
      </div>
    </a-carousel>
    <!-- <div
      class="index-video-container div-background"
      :style="{
        backgroundImage: `url(${icons.index_video_background})`,
      }"
    > -->
    <div class="index-video-container div-background">
      <img :src="icons.index_arrow_yellow" class="index-arrow-yellow" />
      <video class="index-video" :src="video_url" controls></video>
    </div>
    <div class="index-schedule-container">
      <div class="index-schedule-step1 column-center">
        <span style="font-size: 15px"><a style="font-weight: bold; color: #062c3e">合作机构</a><a style="color: #062c3e"
            :href="download_url">点此下载</a></span>
        <span style="text-decoration: underline; font-size: 12px">活动海报、展架等物料</span>
      </div>
      <div class="index-schedule-step2 column-center">
        <span style="
            margin-bottom: 3px;
            font-size: 16px;
            font-weight: bold;
            color: #fcef27;
          ">时间说明</span>
        <div class="row" style="align-items: flex-start">
          <img :src="icons.index_sche_step1_new" style="height: 40px" />
          <!-- <div class="column" style="color: white; font-size: 12px;font-weight:bold">
            <div>报名及提交作品时间：</div>
            <div>2022年6月起</div>
          </div> -->
          <img :src="icons.index_arrow_yellow_sml" class="index-arrow-yellow-sml" />
          <img :src="icons.index_sche_step2_new" style="height: 40px" />
          <!-- <div class="column" style="color: white; font-size: 12px;font-weight:bold">
            <div>截稿日期</div>
            <div>2022年11月</div>
          </div> -->
          <img :src="icons.index_arrow_yellow_sml" class="index-arrow-yellow-sml" />
          <!-- <div class="column" style="color: white; font-size: 12px;font-weight:bold">
            <div>评选与巡展</div>
            <div>2022年11月-12月</div>
          </div> -->
          <img :src="icons.index_sche_step3_new" style="height: 40px" />
          <!-- <img :src="icons.index_arrow_yellow_sml" class="index-arrow-yellow-sml" /> -->
          <!-- <div class="column" style="color: white; font-size: 12px">
            <div>巡展开始</div>
            <div>2022年1月—2月</div>
          </div> -->
          <!-- <img :src="icons.index_sche_step4" style="width: 70px" /> -->
        </div>
      </div>
      <div class="index-schedule-step3 column-center">
        <span class="s-title">参展步骤：第1步</span>
        <a class="s-content" @click="goIntro">点此阅读参加须知</a>
      </div>
      <div class="index-arrow-div column-center">
        <img :src="icons.index_arrow_orange_sml" style="width: 25px" />
      </div>
      <div class="index-schedule-step4 column-center">
        <span class="s-title">参展步骤：第2步</span>
        <a class="s-content" @click="goArtists">点此查看如何创作</a>
      </div>
      <div class="index-arrow-div column-center">
        <img :src="icons.index_arrow_orange_sml" style="width: 25px" />
      </div>
      <div class="index-schedule-step5 column-center">
        <span class="s-title">参展步骤：第3步</span>
        <a class="s-content" @click="goJoin" style="font-size: 18px">点此进入报名</a>
      </div>
    </div>
    <div class="index-nouvelles-container">
      <img :src="icons.index_word_news" />
      <div class="index-nouvelles-list-container">
        <div class="index-nouvelles-wrapper">
          <div v-for="item in news_list" :key="item.id" class="index-nouvelles-item-container div-background" :style="{
              backgroundImage: `url(${item.img_url})`,
            }" @click="goDetail(item.news_id)">
            <div class="index-nouvelles-item-content">
              <div class="item-content-title">{{ item.title }}</div>
              <div class="item-content-time">{{ item.create_time_format }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-nouvelles-direction-container">
        <img :src="icons.index_page_prev" style="height: 30px; cursor: pointer; margin-right: 8px"
          @click="nouvellesScroll(-1)" />
        <img :src="icons.index_page_next" style="height: 30px; cursor: pointer; margin-left: 8px"
          @click="nouvellesScroll(1)" />
      </div>
      <div class="row" style="width: 90vw; justify-content: flex-end">
        <img :src="icons.index_view_all" style="
            height: 30px;
            cursor: pointer;
            justify-self: flex-end;
            margin-top: -35px;
          " @click="goNews" />
      </div>
    </div>
    <div class="index-salle-container">
      <img :src="icons.index_word_online_gallery" />
      <div class="row" style="width: 100vw; justify-content: flex-end">
        <img :src="icons.index_arrow_orange" style="margin-top: -202px; height: 220px" />
      </div>
      <div class="index-salle-list-container">
        <div class="index-salle-wrapper">
          <div v-for="item in opus_list" :key="item.opus_id" class="index-salle-item-container"
            @click="showDesignModal(item)">
            <img :src="item.opus_img_url" style="height: 350px; margin-bottom: 17px" />
            <div class="row">
              <img :src="icons.triangle" style="
                  width: 18px;
                  height: 15px;
                  transform: rotate(90deg);
                  margin-right: 10px;
                " />
              <div class="index-salle-item-avatar div-background" :style="{
                  backgroundImage: `url(${item.comment_img_url})`,
                }"></div>
              <div class="index-salle-item-comment multi-text">
                {{ item.comment_content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-nouvelles-direction-container">
        <img :src="icons.index_page_prev" style="height: 30px; cursor: pointer; margin-right: 8px"
          @click="salleScroll(-1)" />
        <img :src="icons.index_page_next" style="height: 30px; cursor: pointer; margin-left: 8px"
          @click="salleScroll(1)" />
      </div>
      <div class="row" style="width: 90vw; justify-content: flex-end">
        <img :src="icons.index_view_all2" style="
            height: 30px;
            cursor: pointer;
            justify-self: flex-end;
            margin-top: -35px;
          " @click="goShow" />
      </div>
      <!-- <img
        :src="icons.index_all"
        style="width: 170px; height: 60px; margin-top: 45px; cursor: pointer"
        @click="goShow"
      /> -->
    </div>

    <design-modal ref="designModal" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as type from "../../store/type";
import * as eventHelper from "../../utils/eventHelper";
import DesignModalVue from "./Modal/DesignModal.vue";
export default {
  components: {
    "design-modal": DesignModalVue,
  },
  data() {
    return {
      icons: this.$root.icons,

      video_url: null,
      index_banner_list: [],

      download_url:
        "https://iacc-1257406365.cos.ap-beijing.myqcloud.com/%E5%90%88%E4%BD%9C%E6%8A%A5%E5%90%8D%E7%82%B9%E8%B5%84%E6%96%99%E5%8C%85.zip",
    };
  },
  mounted() {
    this.currentNouveTransformX = 0;
    this.currentSalleTransformX = 0;

    this.$store.commit(type.CLEAR_NEWS_LIST);
    this.$store.commit(type.CLEAR_OPUS_LIST);
    this.getNewsList();
    this.getOpusList();

    this.getBanner();
    this.getVideo();

    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#e9bf74",
        fontColor: "#282828",
      });
    }, 500);
  },
  computed: {
    ...mapState({
      news_list: (state) => state.news.news_list,
      opus_list: (state) => state.opus.opus_list,
    }),
  },
  methods: {
    ...mapActions({
      newsGetListAction: "newsGetList",
      opusGetListAction: "opusGetList",
      positionGetContentAction: "positionGetContent",
    }),
    goNewPage(url) {
      window.open(url);
    },
    getBanner() {
      this.positionGetContentAction({
        position_id: 1,
      }).then((res) => {
        this.index_banner_list = res;
      });
    },
    getVideo() {
      this.positionGetContentAction({
        position_id: 2,
      }).then((res) => {
        this.video_url = res.file_url;
      });
    },
    goArtists() {
      // this.$router.push({
      //   name: "artists",
      // });
      this.$router.push({
        name: "create",
      });
    },
    nouvellesScroll(type) {
      let container = document.getElementsByClassName(
        "index-nouvelles-wrapper"
      )[0];

      let containerWidth = container.clientWidth;
      let screenWidth = document.body.clientWidth;
      let scrollPx = 541;

      let maxTransformX = containerWidth - screenWidth;
      let minTransformX = 0;

      if (type == -1) {
        // 往左滚动
        this.currentNouveTransformX = this.currentNouveTransformX - scrollPx;
        if (this.currentNouveTransformX < minTransformX) {
          this.currentNouveTransformX = minTransformX;
        }
      } else {
        // 往右滚
        this.currentNouveTransformX = this.currentNouveTransformX + scrollPx;
        if (this.currentNouveTransformX > maxTransformX) {
          this.currentNouveTransformX = maxTransformX;
        }
      }

      container.style.transform =
        "translateX(" + -this.currentNouveTransformX + "px)";
    },
    salleScroll(type) {
      let container = document.getElementsByClassName("index-salle-wrapper")[0];

      let containerWidth = container.clientWidth;
      let screenWidth = document.body.clientWidth;
      let scrollPx = 500;

      let maxTransformX = containerWidth - screenWidth + 120;
      let minTransformX = 0;

      if (type == -1) {
        // 往左滚动
        this.currentSalleTransformX = this.currentSalleTransformX - scrollPx;
        if (this.currentSalleTransformX < minTransformX) {
          this.currentSalleTransformX = minTransformX;
        }
      } else {
        // 往右滚
        this.currentSalleTransformX = this.currentSalleTransformX + scrollPx;
        if (this.currentSalleTransformX > maxTransformX) {
          this.currentSalleTransformX = maxTransformX;
        }
      }

      container.style.transform =
        "translateX(" + -this.currentSalleTransformX + "px)";
    },
    goShow() {
      this.$router.push({
        name: "show",
      });
    },
    goNews() {
      this.$router.push({
        name: "news",
      });
    },
    goDetail(id) {
      this.$router.push({
        name: "news-detail",
        params: {
          news_id: id,
        },
      });
    },
    showDesignModal(item) {
      this.$refs.designModal.open(item);
    },
    getNewsList() {
      this.newsGetListAction({
        page_size: 5,
        page_index: 0,
      })
        .then((res) => {})
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getOpusList() {
      this.opusGetListAction({
        page_size: 5,
        page_index: 0,
      })
        .then((res) => {})
        .catch((error) => {
          this.$message.error(error);
        });
    },
    goIntro() {
      this.$router.push({
        name: "intro",
      });
    },
    goJoin() {
      this.$router.push({
        name: "join",
      });
    },
    onChange(current) {},
  },
};
</script>

<style scoped>
.index-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-banner {
  width: 86vw;
  height: auto;
}
.index-video-container {
  width: 100vw;
  padding: 4vw 12vw;
  padding-left: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.index-arrow-yellow {
  width: 12%;
  align-self: flex-start;
}
.index-video {
  width: 75%;
}
.index-schedule-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 92px;
  background-color: red;
}
.index-schedule-step1 {
  background-color: #4091ce;
  justify-content: center;
  width: 14vw;
  height: 92px;
}
.index-schedule-step2 {
  background-color: #ff4b01;
  justify-content: center;
  width: 38vw;
  height: 92px;
}
.index-arrow-yellow-sml {
  width: 18px;
  align-self: flex-start;
  margin: 0px 3px;
}
.index-arrow-div {
  justify-content: center;
  width: 0vw;
  height: 92px;
  z-index: 999;
  border-left: 1px solid #107b97;
}
.index-schedule-step3 {
  background: #062c3e;
  justify-content: center;
  width: 16vw;
  height: 92px;
}
.index-schedule-step4 {
  background: #062c3e;
  justify-content: center;
  width: 16vw;
  height: 92px;
}
.index-schedule-step5 {
  background: #062c3e;
  justify-content: center;
  width: 16vw;
  height: 92px;
}
.s-title {
  font-size: 17px;
  color: white;
}
.s-content {
  font-size: 12px;
  color: white;
  margin-top: 3px;
  line-height: 24px;
  border-bottom: 1px solid white;
}
.index-nouvelles-container {
  width: 100vw;
  background: #f0e6d4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
}
.index-nouvelles-list-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow-x: scroll;
}
.index-nouvelles-wrapper {
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease-in;
}
.index-nouvelles-item-container {
  display: flex;
  flex-direction: column;
  width: 505px;
  height: 312px;
  background-color: #acacac;
  margin-right: 36px;
  cursor: pointer;
}
.index-nouvelles-item-content {
  display: none;
}
.index-nouvelles-item-container:hover .index-nouvelles-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 25px 20px 30px 25px;
  color: white;
  background: rgba(6, 44, 62, 0.95);
}
.item-content-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
}
.item-content-time {
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
}
.index-nouvelles-item-container:last-child {
  margin-right: 0px;
}
.index-nouvelles-direction-container {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.index-salle-container {
  width: 100vw;
  background: #062c3e;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
}
.index-salle-en {
  margin-top: 85px;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.index-salle-zh {
  margin-top: 20px;
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.index-salle-list-container {
  margin-top: 50px;
  padding-left: 120px;
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow-x: scroll;
}
.index-salle-wrapper {
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease-in;
}
.index-salle-item-container {
  margin-right: 45px;
  cursor: pointer;
}
.index-salle-item-container:last-child {
  margin-right: 0px;
}
.index-salle-item-avatar {
  border: 3px solid #ffffff;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  margin-right: 20px;
}
.index-salle-item-comment {
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  flex: 1;
  -webkit-line-clamp: 3;
}
.index-carousel,
.index-swapper {
  position: relative;
  width: 100vw;
}
.index-circle-dot {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  margin-right: 12px !important;
}
.index-circle-dot:last-child {
  margin-right: 0px;
}
.index-swapper-item-container {
  position: relative;
  width: 100vw;
  height: 912px;
  cursor: pointer;
}
.index-swapper-content-container {
  position: absolute;
  left: 120px;
  bottom: 150px;
}
.index-swapper-item-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
}
.index-swapper-item-desc {
  width: 800px;
  font-family: PingFangSC-Regular;
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 0;
  margin-top: 15px;
}
</style>